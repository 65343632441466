/* eslint-disable */
<template>
  <div style="margin-left:-5px;padding-bottom:20px">

<!--
    <div style="position: absolute; right: 0px;">
      <img  height="70" src="/icons/png/dragees_134x100.png" />
    </div>

    <v-row class="bottom-header-flex" style="height:70px;">
      <span style="font-family: Dosis, sans-serif;
        font-size: 30px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.22;
        letter-spacing: normal;
        text-align: left;
        color: #ffffff;
        text-transform: uppercase;
        align-self: center;
        padding-left: 60px;
        z-index:1;
      ">Calculateur CO<sub>2</sub> - Omega TP</span>
    </v-row>

    <div style="display:flex">
      <div style="display:block;width:50%;padding-top:20px;padding-left:20px" >
      Rapport généré le {{date_rapport}}
      </div>
      <div style="display:block;width:50%">
        <div style="text-align:right;padding-top:20px;padding-right:20px">
          <div><b>{{entreprise.nom}}</b></div>
          <div>{{entreprise.adresse_rue}}</div>
          <div>{{entreprise.adresse_CP}} {{entreprise.adresse_ville}}</div>
          <div>N° SIREN : {{entreprise.siren}}</div>
        </div>
      </div>
    </div>
-->
  </div>
</template>

<script>
        export default {
        name: 'Entete',
        components: {},
        props :['entreprise','date_rapport']
        }

</script>